function loadScript(url: string, callback: any) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = url;

  // There are several events for cross browser compatibility.
  // @ts-ignore
  script.onreadystatechange = callback;
  script.onload = callback;

  // Fire the loading
  document.head.appendChild(script);
}

// Primus is globally available on window
declare let Primus: any;

let primusPendingPromise: Promise<any>;

export async function loadPrimus(baseUrl: string): Promise<any> {
  // do this only once
  if (!primusPendingPromise) {
    primusPendingPromise = new Promise((resolve, reject) => {
      const primusScriptUrl = baseUrl + '/primus/primus.js';

      loadScript(primusScriptUrl, () => {
        // check if Primus is avaiable
        if (Primus && typeof Primus === 'function') {
          resolve(Primus);
        } else {
          reject();
        }
      });
    });
  }

  return primusPendingPromise;
}
