export interface UiCommand {
  command: string;

  [propName: string]: any;
}

export function sendCommandToUi(command: UiCommand) {
  console.log('[vui] sending', command);
  window.top?.postMessage(
    // preserve undefined values: required for addToViewState to remove keys
    JSON.stringify(command, (k, v) => (v === undefined ? null : v)),
    '*',
  );
}
