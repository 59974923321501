import { reverse } from 'lodash';

// https://sashat.me/2017/01/11/list-of-20-simple-distinct-colors/
export const red = { hex: '#e6194b', rgb: { r: 230, g: 25, b: 75 } };
export const green = { hex: '#3cb44b', rgb: { r: 60, g: 180, b: 75 } };
export const yellow = { hex: '#ffe119', rgb: { r: 255, g: 225, b: 25 } };
export const blue = { hex: '#0082c8', rgb: { r: 0, g: 130, b: 200 } };
export const orange = { hex: '#f58231', rgb: { r: 245, g: 130, b: 48 } };
export const purple = { hex: '#911eb4', rgb: { r: 145, g: 30, b: 180 } };
export const cyan = { hex: '#46f0f0', rgb: { r: 70, g: 240, b: 240 } };
export const magenta = { hex: '#f032e6', rgb: { r: 240, g: 50, b: 230 } };
export const lime = { hex: '#d2f53c', rgb: { r: 210, g: 245, b: 60 } };
export const pink = { hex: '#fabebe', rgb: { r: 250, g: 190, b: 190 } };
export const teal = { hex: '#008080', rgb: { r: 0, g: 128, b: 128 } };
export const lavender = { hex: '#e6beff', rgb: { r: 230, g: 190, b: 255 } };
export const brown = { hex: '#aa6e28', rgb: { r: 170, g: 110, b: 40 } };
export const beige = { hex: '#fffac8', rgb: { r: 255, g: 250, b: 200 } };
export const maroon = { hex: '#800000', rgb: { r: 128, g: 0, b: 0 } };
export const mint = { hex: '#aaffc3', rgb: { r: 170, g: 255, b: 195 } };
export const olive = { hex: '#808000', rgb: { r: 128, g: 128, b: 0 } };
export const coral = { hex: '#ffd8b1', rgb: { r: 255, g: 215, b: 180 } };
export const navy = { hex: '#000080', rgb: { r: 0, g: 0, b: 128 } };
export const grey = { hex: '#808080', rgb: { r: 128, g: 128, b: 128 } };
export const white = { hex: '#FFFFFF', rgb: { r: 255, g: 255, b: 255 } };
export const black = { hex: '#000000', rgb: { r: 0, g: 0, b: 0 } };

export const twentySimpleDistinctColors = [
  red,
  green,
  yellow,
  blue,
  orange,
  purple,
  cyan,
  magenta,
  lime,
  pink,
  teal,
  lavender,
  brown,
  beige,
  maroon,
  mint,
  olive,
  coral,
  navy,
  grey,
  // white,
  black,
];

// from https://blog.graphiq.com/finding-the-right-color-palettes-for-data-visualizations-fcd4e707a283
// fg does not like these
export const chartColors = [
  '#d8edc6',
  '#a6dfcb',
  '#75cfcf',
  '#38c0d5',
  '#00acd2',
  '#0092c3',
  '#0e77b3',
  '#1b5da3',
  '#1b4293',
  '#192984',
  '#151e69',
  '#10154e',
];
export const chartColorsReverse = reverse([...chartColors]); // copy array, reverse changes it

// better but not enough
export const superChartBlau = '#006590';
export const superChartOrange = '#f2a538';
export const superChartColors = [superChartBlau, superChartOrange];

// likable chart colors
export const betterChartColors = ['#724F73', '#7C6C8C', '#03658C', '#5EA4BF', '#ADCCD9', '#dedede'];
