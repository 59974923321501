export function germanLocale() {
  let de = {
    delimiters: {
      thousands: '.',
      decimal: ',',
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't',
    },
    ordinal: () => {
      return '.';
    },
    currency: {
      symbol: '€',
    },
  };

  return de;
}
