import { values } from 'lodash';
import { roseDayjs } from './rose-dayjs';

export const today: [Date, Date] = [roseDayjs().startOf('day').toDate(), roseDayjs().endOf('day').toDate()];

export const yesterday: [Date, Date] = [
  roseDayjs().subtract(1, 'days').startOf('day').toDate(),
  roseDayjs().subtract(1, 'days').endOf('day').toDate(),
];

export const last7Days: [Date, Date] = [
  roseDayjs().subtract(6, 'days').startOf('day').toDate(),
  roseDayjs().endOf('day').toDate(),
];

export const next7Days: [Date, Date] = [
  roseDayjs().startOf('day').toDate(),
  roseDayjs().add(7, 'days').endOf('day').toDate(),
];

export const last30Days: [Date, Date] = [
  roseDayjs().subtract(29, 'days').startOf('day').toDate(),
  roseDayjs().endOf('day').toDate(),
];

export const last365Days: [Date, Date] = [
  roseDayjs().subtract(365, 'day').startOf('day').toDate(),
  roseDayjs().endOf('day').toDate(),
];

export const currentMonth: [Date, Date] = [roseDayjs().startOf('month').toDate(), roseDayjs().endOf('month').toDate()];
export const lastMonth: [Date, Date] = [
  roseDayjs().subtract(1, 'month').startOf('month').toDate(),
  roseDayjs().subtract(1, 'month').endOf('month').toDate(),
];

export const last3Months: [Date, Date] = [roseDayjs().subtract(3, 'month').toDate(), roseDayjs().toDate()];
export const last12Months: [Date, Date] = [roseDayjs().subtract(12, 'month').toDate(), roseDayjs().toDate()];

export const currentQuarter: [Date, Date] = [
  roseDayjs().startOf('quarter').toDate(),
  roseDayjs().endOf('quarter').toDate(),
];
export const lastQuarter: [Date, Date] = [
  roseDayjs().subtract(1, 'quarter').startOf('quarter').toDate(),
  roseDayjs().subtract(1, 'quarter').endOf('quarter').toDate(),
];

export const currentYear: [Date, Date] = [roseDayjs().startOf('year').toDate(), roseDayjs().endOf('year').toDate()];
export const lastYear: [Date, Date] = [
  roseDayjs().subtract(1, 'year').startOf('year').toDate(),
  roseDayjs().subtract(1, 'year').endOf('year').toDate(),
];

export const olderThanOneYear: [Date, Date] = [
  roseDayjs().subtract(40, 'year').startOf('year').toDate(),
  roseDayjs().subtract(1, 'year').toDate(),
];

export const allRanges: any = {
  heute: { name: 'Heute', range: today },
  last7Days: { name: 'Letzten 7 Tage', range: last7Days },
  next7Days: { name: 'Nächsten 7 Tage', range: next7Days },
  currentMonth: { name: 'Dieser Monat', range: currentMonth },
  lastMonth: { name: 'Letzter Monat', range: lastMonth },
  last3Months: { name: 'Letzte 3 Monate', range: last3Months },
  currentQuarter: { name: 'Dieses Quartal', range: currentQuarter },
  lastQuarter: { name: 'Letztes Quartal', range: lastQuarter },
  currentYear: { name: 'Dieses Jahr', range: currentYear },
  lastYear: { name: 'Letztes Jahr', range: lastYear },
  last12Months: { name: 'Letzte 12 Monate', range: last12Months },
  olderThanOneYear: { name: 'Älter als ein Jahr', range: olderThanOneYear },
};

export const allRangesKeyValue = {
  Heute: today,
  'Letzten 7 Tage': last7Days,
  'Nächsten 7 Tage': next7Days,
  'Dieser Monat': currentMonth,
  'Letzter Monat': lastMonth,
  'Letzte 3 Monate': last3Months,
  'Dieses Quartal': currentQuarter,
  'Letztes Quartal': lastQuarter,
  'Dieses Jahr': currentYear,
  'Letztes Jahr': lastYear,
  'Letzte 12 Monate': last12Months,
  'Älter als ein Jahr': olderThanOneYear,
};

export function defaultRanges() {
  let ranges: any = {};
  for (const rkey in allRanges) {
    if (allRanges.hasOwnProperty(rkey)) {
      const r = allRanges[rkey];
      ranges[r.name] = r.range;
    }
  }
  return ranges;
}

export function getDefaultRangesNoMoment(): any[] {
  return convertToBsCustomDates(allRanges);
}

export function convertToBsCustomDates(ranges: any[]): any[] {
  return values(ranges).map(range => {
    return {
      label: range.name,
      value: [range.range[0].toDate(), range.range[1].toDate()],
    };
  });
}

export function getDefaultDaterangeNoMoment() {
  return [lastMonth[0], lastMonth[1]];
}
